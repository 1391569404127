// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "css/application"
import "map"
import 'bootstrap'
import '@popperjs/core'

const images = require.context('../images', true)

require('../css/application.scss')
require("../src/noticeContainer")
require("../src/modal")
require("./map")

// what is this?
// document.addEventListener("turbolinks:load", function() {
//   $(function () {
//       $('[data-toggle="tooltip"]').tooltip()
//       $('[data-toggle="popover"]').popover()
//   })
// })

require.context('../images', true)
//require('../css/main.scss')

document.addEventListener('turbolinks:before-cache', () => {
  const els = document.querySelectorAll('.clear-before-cache')
  els.forEach((el) => el.innerHTML = '')
})
