import { Controller } from 'stimulus'
import I18n from 'i18n-js/index.js.erb'

let url = '../../../api/v1/equips'
let formatType
export default class extends Controller {
  static targets = ["field","equipDiv"]
  connect() {
    this.disabledEquipFormat()
    if(document.URL.includes('edit')) { this.changeWorksite() }
  }


  changeWorksite() {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    this.populateSelect('worksite', 'transfer', I18n.t('js.equip.without_worksite'))
  }
  
  changeEquip() {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    this.populateSelect('transfer', 'equip', I18n.t('js.equip.without_transfer'))
  }

  disabledEquipFormat() {
    formatType = document.querySelector('#format')
    console.log("formatType:",formatType.value);
    console.log("el valor de formatType es igual a TA1?:",formatType.value == 'TA1');
    let equips = this.equipDivTarget
    if(formatType.value == 'TA1' || formatType.value == undefined){
      equips.classList.add('d-none')
    }else{
      equips.classList.remove('d-none')
    }
  }

  clearSelectedDropdown(){
    let transferDropDown = document.querySelector('#transfer')
    let equipDropDown = document.querySelector('#equip')
    equipDropDown.innerHTML = ""
    transferDropDown.selectedIndex = 0
  }
  
  populateSelect(origin, target, message) {
    let fields = this.fieldTargets  
    let originID = fields.find( f => f.id == origin).value
    let targetField = fields.find( f => f.id == target)
    formatType = document.getElementById('format')
    fetch(`${url}/${originID}/${target}s`)
      .then(response => response.json())
      .then(data => {
        targetField.innerHTML = ''
        targetField.classList.remove('disabled')
        if (data.length == 0) {
          const opt = document.createElement('option')
          opt.value = ''
          opt.innerHTML = message
          targetField.appendChild(opt)
        }
        else {
          data.forEach(item => {
            if(formatType &&formatType.value == 'TA1' && item.kind !== 'ghost_equipment'){return}
            if(formatType &&item.kind == 'ghost_equipment' && formatType.value !== 'TA1'){ return } //makes the ghost_equipment dessapear in the selection for non TA1 sensors
            const opt = document.createElement('option')
            opt.value = item.id
            opt.innerHTML = item.name
            targetField.appendChild(opt)
          })
        }
      })
  }
}
