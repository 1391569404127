import { Controller } from "stimulus"

let sidebarContainer = null
let closeAreaContainer = null
export default class extends Controller {
  static targets = [ 'sidebar', 'close' ]

  connect(){
    if(window.chartInterval) clearInterval(window.chartInterval);
    sidebarContainer = this.sidebarTarget
    closeAreaContainer = this.closeTarget
    let closeArea = document.querySelector('#close-sidebar-area')
    closeArea.addEventListener('click', () =>{
      toggleSidebar()
    })
  }

  sidebar() {
    toggleSidebar()
  }

  close() {
    toggleSidebar()
  }

}

let toggleSidebar = () => {
  sidebarContainer.classList.toggle('extended')
  closeAreaContainer.classList.toggle('extended')
}