import ApexCharts from 'apexcharts'
import { Controller } from 'stimulus';
import consumer from "../channels/consumer"
import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  static targets = ['field','sensor','chartContainer']
  connect() {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    let container = this.buildContainer(this.element.clientWidth)
    this.element.appendChild(container)
    let data = this.fetchData(format)
    let format = this.getFormat(this.element.dataset.format, this.element.dataset.sensor)
    let ref = this.element.dataset.ref
    let element = this.element
    let chart = this.renderChart(container, format.defaultOptions(ref, format, element, execUpdateSeries))
    updateChart(chart, format.getOptions(), data)
    if (!this.element.dataset.flag) {
      this.subscribeChat(ref, format, chart, element)
    }
  }

  fetchData(format, data) {
    let sensor = this.element.dataset.sensor
    let name = this.element.dataset.name
    let sensors = sensor.split(',')
    let names = name.split(',')
    let result = []
    const states = ( data == null) ? JSON.parse(this.element.dataset.states) : data
    let lastLife = states[states.length-1] == undefined ? 0 :  states[states.length-1].lifes
    if(sensor === 'live'){return [{ name: I18n.t('js.lifespan'), data: lastLife }]}

    sensors.forEach( (sensorName, indx) => {
      let filteredStates = states.filter((state)=> {
        if(state[sensorName] === undefined) { return false}
        return true
      })
      let processedStates =  filteredStates.map((state) => { 
        return { x: new Date(state.created_at), y: state[sensorName] } })
      result.push({ name: names[indx], data: processedStates })
    })
    return result
  }

  getFormat(name, sensor) {
    if(name === 'CA1' || name === 'RP1' || name === 'TA1'){let Format = require(`../formats/${name.toLowerCase()}_format`).default
    return new Format()}
    let Format = require(`../formats/${name.toLowerCase()}_${sensor}_format`).default
    
    return new Format()
  }

  renderChart(container, options, data = {}) {
    let chart = new ApexCharts(container, options)
    chart.render()
    return chart
  }

  buildContainer(width) {
    let container = document.createElement('div')
    container.setAttribute('width', width)
    container.setAttribute('height', (width*9/16))

    return container
  }

  subscribeChat(ref, format, chart, element){
    // let sensors = this.sensorTargets
    consumer.subscriptions.create(
      {channel: 'SensorChannel',
      sensor_ref: ref}, 
      {
        connected() {
        },
      
        disconnected() {
          // Called when the subscription has been terminated by the server
        },
      
        received(data) {
          updateChartData(format, chart, ref, element)
        }
      });
  }
}

const updateChartData = (format, chart, ref, element, startDate = null, endDate = null) => {
  startDate
  fetch(`/api/v1/telemetries/get_sensor_telemetries?sensor_ref=${ref}&start_date=${startDate}&end_date=${endDate}`)
  .then(response => response.json())
  .then(data => { 
    let newData = fetchData(format, data, element)
    updateChart(chart, format.getOptions(), newData)
   })
}

const fetchData = (format, data, element) => {
  let sensor = element.dataset.sensor
  let name = element.dataset.name
  let sensors = sensor.split(',')
  let names = name.split(',')
  let result = []
  const states = ( data == null) ? JSON.parse(element.dataset.states) : data
  let lastLife = states[0] == undefined ? 0 :  states[0].lifes
  if(sensor === 'live'){return [{ name: 'Vida útil', data: lastLife }]}
  
  sensors.forEach( (sensorName, indx) => {
    let processedStates =  states.map((state) => { return { x: new Date(state.created_at), y: state[sensorName] } })
    result.push({ name: names[indx], data: processedStates })
  })
  return result
}

const updateChart = (chart, newOptions = null, newSeries = null) => {
  if(newOptions === null && newSeries === null) { return }
  if(newOptions === null && newSeries !== null) {
    return chart.updateSeries(newSeries)
  }
  if(newOptions !== null && newSeries === null) {
    return chart.updateOptions(newOptions)
  }
  newOptions.series = newSeries
  chart.updateOptions(newOptions)
}

const execUpdateSeries = (ref, startDate, endDate, format, element, chartId) => {
  fetch(`/api/v1/telemetries/get_sensor_telemetries?sensor_ref=${ref}&start_date=${startDate}&end_date=${endDate}`)
  .then(response => response.json())
  .then(data => { 
    let newData = fetchData(format, data, element)
    let payload = []
    if(chartId === 'ca1'){
      payload.push({name: I18n.t('js.chart.current_pressure'), data: newData[0]['data']});
      payload.push({name: I18n.t('js.chart.set_pressure'), data: newData[1]['data']});
    } 
    else if(chartId === 'ta1'){
      payload.push({name: I18n.t('js.chart.input_height'), data: newData[0]['data']});
      payload.push({name: I18n.t('js.chart.output_height'), data: newData[1]['data']});
    }
    else {
      payload.push({data: newData[0]['data']});
    }
    ApexCharts.exec(chartId, 'updateSeries', payload, true);
  })
}
