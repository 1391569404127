import { Controller } from "stimulus"
export { selectize } from 'selectize'
import 'selectize/dist/js/selectize.min.js'
import 'selectize/dist/css/selectize.css'

export default class extends Controller {
  static targets = [ 'supervisors', 'selectedSupervisors']
  // TODO: remove ugly jquery
  connect() {
    let selectedSupervisors = this.selectedSupervisorsTarget.value.split(' ')
    let dropdownMultiple = $("#supervisors").selectize({ plugins: ["remove_button"]})
    let selectize = dropdownMultiple[0].selectize
    selectize.setValue(selectedSupervisors, false)
  }
}
