import ApexCharts from 'apexcharts'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    const container = this.buildContainer()
    this.element.appendChild(container)
    let telemetry = this.fetchData()
    this.updateValue(telemetry.btt, container)
  }

  fetchData() {
    const telemetry = JSON.parse(this.element.dataset.telemetry)
    return telemetry
  }

  appendBar(klass, container) {
    let bar = document.createElement('div')
    bar.classList.add('btt-bar', `btt-bar-${klass}`)
    container.appendChild(bar)
  }

  updateValue(level, container) {
    
    if(level > 0.25) { this.appendBar('25', container) }
    if(level > 0.5)  { this.appendBar('50', container) }
    if(level > 0.75) { this.appendBar('75', container) }
    if(level >= 0.9) { this.appendBar('90', container) }
  }

  buildContainer() {
    let container = document.createElement('div')
    container.classList.add('battery-display')
    return container
  }
}