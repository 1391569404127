
class BaseFormat {
  
  processItem(item, index) {
  
  }

  defaultOptions() {
    return {
      
    }
  }

  getOptions() {

  }

  getData() {
    
  }


}

export default BaseFormat;