import { Controller } from "stimulus"
import moment from 'moment';
import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  static targets = ['telemetry', 'card', 'field', 'timer']

  open(){
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    let currentTelemetry = JSON.parse(this.telemetryTarget.dataset.value)
    let timeToUpdate = moment(currentTelemetry.time_to_update)
    let secondsToUpdate =  Math.round(timeToUpdate.diff(moment())/1000)
    if (timeToUpdate.isSameOrAfter(moment())) {
      this.cardTargets.forEach((card)=>{
        card.classList.remove('d-none')
      })
      this.fieldTargets.forEach((field)=>{
        field.classList.add('d-none')
      })
      this.timerTargets.forEach((timer)=>{
        let secondsInMinutes = Math.round(secondsToUpdate/60)
        let rttuMessage = secondsToUpdate > 60 ? I18n.t('js.chart.bloqued_min',{ secondsInMinutes: secondsInMinutes}) : I18n.t('js.chart.bloqued_seg', {secondsToUpdate: secondsToUpdate})
        timer.innerHTML = rttuMessage
      })
    } else {
      this.cardTargets.forEach((card)=>{
        card.classList.add('d-none')
      })
      this.fieldTargets.forEach((field)=>{
        field.classList.remove('d-none')
      })
    }
  }
}
