import BaseFormat from "./base_format"
import moment from 'moment'
import I18n from 'i18n-js/index.js.erb'
class GW1BTTFormat extends BaseFormat {

  xAxisAnnotations() {
    let anns = []
    for(let i = 0; i < 7 ; i += 2) {
      let obj = {
        x: moment().startOf('day').subtract(i, 'days').toDate(),
        x2: moment().startOf('day').subtract(i + 1, 'days').toDate(),
        fillColor: '#CCCCCC',
        opacity: 0.4,
      }
      anns.push(obj)
    }
    return anns
  }
  defaultOptions(ref, format, element, execUpdateSeries) {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    return {
      chart: { 
        id: 'gw2vbatt',
        animations: {
          enabled: false
        },
        type: 'line', 
        width: '100%',
        height: '500',
        stacked: false,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 25,
          tools: {
            pan: false
          },
        },
        events: {
          beforeResetZoom: function() {
            let today = new Date();
            let pastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            let startDate = pastWeek.getTime() / 1000;
            let endDate = Date.now();
            execUpdateSeries(ref, startDate, endDate, format, element, 'gw2vbatt')
          },
          beforeZoom: function(_chartContext, {xaxis}){
            let startDate = xaxis.min / 1000;
            let endDate = xaxis.max > Date.now() ? Date.now() : xaxis.max / 1000;
            execUpdateSeries(ref, startDate, endDate, format, element, 'gw2vbatt')
          }
        }
     },
      title: {
        text: I18n.t('js.chart.gw2batt_title'),
        align: 'left',
        offsetX: 10,
        offsetY: 0,
        style: {
          fontSize:  '15px',
        },
      },
      annotations: {
          yaxis: [{
            y: 0,
            y2: 0.2,
            borderColor: '#EE0606',
            fillColor: '#CC1111',
            opacity: 0.2,
          }],
          xaxis: this.xAxisAnnotations()
      },
      yaxis: {
        min: 2.7,
        max: 4.2,
        decimalsInFloat: 2,
        seriesName: I18n.t('js.chart.gw2batt_title'),
        labels: {
          formatter: (value) => { return `${Math.round((value + Number.EPSILON))}` }
        }
      },
      tooltip: {
        x: {
          show: true, 
          format: 'dd-MM-yyyy H:mm',
        },
        y: {
          formatter: (val) => `${(val).toFixed(2)}`,
          title: I18n.t('js.chart.gw2batt_title'),
        }
      },
      xaxis: { 
        type: 'datetime',
        labels: { datetimeUTC: false}
      },
      series: [
        { data: [] }
      ]
    }
  }
}

export default GW1BTTFormat