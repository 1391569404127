import { Controller } from 'stimulus';
const { validate, clean, format } = require('rut.js')

let string_pattern = /^[a-zA-Z]+$/
let email_pattern = /\S+@\S+\.\S+/
let userWorksitesField
let hiddenWorksitesField
export default class extends Controller {
  static targets = ["button", "field"]
  connect(){
    userWorksitesField = document.getElementById('worksite');
    hiddenWorksitesField = document.getElementById('worksite_hidden');

    this.changeRole()
  }

  checkAllParams(){
    let enable = true
    let fields = this.fieldTargets
    if (this.checkRut(fields)) { enable =  false }
    if (this.checkFirstName(fields)) { enable =  false }
    if (this.checkLastName(fields)) { enable =  false }
    if (this.checkEmail(fields)) { enable =  false }
    if (this.checkPassword(fields)) { enable =  false }
    if (this.checkCharge(fields)) { enable =  false }

    if(enable) { userBtn.classList.remove('disabled') }
    else { userBtn.classList.add('disabled') }
  }

  checkRut(fields) {
    let field = fields.find( f => f.id == 'rut')
    field.value = format(field.value)
    return !(validate(field.value))
  }

  checkFirstName(fields){
    let field = fields.find( f => f.id == 'first_name')
    return !(string_pattern.test(field.value))
  }

  checkLastName(fields){
    let field = fields.find( f => f.id == 'last_name')
    return !(string_pattern.test(field.value))
  }

  checkEmail(fields){
    let field = fields.find( f => f.id == 'email')
    return !(email_pattern.test(field.value))
  }

  checkPassword(fields){
    let field = fields.find( f => f.id == 'password')
    return field.value == ""
  }

  checkCharge(fields){
    let field = fields.find( f => f.id == 'charge')
    return field.value == ""
  }

  changeWorksite(){
    hiddenWorksitesField.value = userWorksitesField.value
  }

  changeRole(){
    if (['admin'].includes(document.getElementById('role').value)){
      userWorksitesField.value = Array.from(userWorksitesField.options).find(opt => opt.textContent === 'TECNIPAK').value
      this.changeWorksite()
      userWorksitesField.disabled = true
    }
    else{
      this.changeWorksite()
      userWorksitesField.disabled = false 
    }
  }
}
