import { Controller } from 'stimulus'
import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  static targets = ['supervisor', 'supervisors', 'form', 'send', 'guest', 'equip', 'loginText']
  sendPin(){
    this.generatePin()
  }
  generatePin(){
    let supervisor = null
    if (this.hasSupervisorsTarget) {
      let value = this.supervisorsTarget.value
      if (value === '' || value === undefined){
        this.emptyAlert()
        return
      }
      this.supervisorsTarget.classList.remove('danger')
      this.supervisorsTarget.title = ''
      supervisor = value
    }
    if (this.hasSupervisorTarget) {
      supervisor = this.supervisorTarget.value
    }
    let guest = this.guestTarget.value
    let equip = this.equipTarget.value
    fetch(`/api/v1/guests/generate_pin?supervisor=${supervisor}&guest=${guest}&equip=${equip}`)
    this.showPinForm()
    this.changeText(supervisor)
  }
  dropdownChange(){
    this.supervisorsTarget.classList.remove('danger')
    this.supervisorsTarget.title = ''
  }

  emptyAlert(){
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale()
    this.supervisorsTarget.classList.add('danger')
    this.supervisorsTarget.title = I18n.t('js.supervisors.no_supervisor')
  }

  showPinForm(){
    this.sendTarget.classList.add('d-none')
    this.formTarget.classList.remove('d-none')
  }

  changeText(supervisor){
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale()
    this.loginTextTarget.innerHTML = I18n.t('js.supervisors.login_text', {supervisor})
  }
}
