document.addEventListener('turbolinks:load', () => {
    const container = document.querySelector('.notice-container')
    if(container === null) { return }
  
    const removeNoticeContainer = () => {
      container.classList.add('dismissed')
    }
  
    const btn = container.querySelector('.close-x')
    setTimeout(removeNoticeContainer, 10000)
    setTimeout(() => container.classList.add('countdown'), 500)
    btn.addEventListener('click', removeNoticeContainer)
  })
  