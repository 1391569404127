import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["field", 'selected', 'row']


  select_transfer(event){
    if (document.querySelectorAll('.popover')[0]) {
      bootstrap.Popover.getInstance(document.querySelectorAll('.popover')[0]).hide()
    }
    let transferField = document.querySelector('#transfer_id')
    let transferId = event.target.dataset.transfer
    transferField.value = ( transferId === undefined ? event.target.parentNode.dataset.transfer : transferId )
  }
}
