import ApexCharts from 'apexcharts'
import { Controller } from 'stimulus';
import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  static targets = ['field','sensor','chartContainer']
  connect() {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    let container = this.buildContainer(this.element.clientWidth)
    this.element.appendChild(container)
    let data = this.fetchData(format)
    let format = this.getFormat(this.element.dataset.format, this.element.dataset.sensor)
    let chart = this.renderChart(container, format.defaultOptions())
    let ref = this.element.dataset.ref
    let element = this.element
    updateChartData(format, chart, ref, element)
  }

  fetchData(format, data) {
    let sensor = this.element.dataset.sensor
    let name = this.element.dataset.name
    let sensors = sensor.split(',')
    let names = name.split(',')
    let result = []
    const states = ( data == null) ? JSON.parse(this.element.dataset.states) : data
    let lastLife = states[0] == undefined ? 0 :  states[0].lifes
    if(sensor === 'live'){return [{ name: I18n.t('js.lifespan'), data: lastLife }]}
    
    sensors.forEach( (sensorName, indx) => {
      let processedStates =  states.map((state) => { return { x: new Date(state.created_at), y: state[sensorName] } })
      result.push({ name: names[indx], data: processedStates })
    })
    return result
  }

  getFormat(name, sensor) {
    if(name === 'CA1' || name === 'RP1'){let Format = require(`../formats/${name.toLowerCase()}_format`).default
    return new Format()}
    let Format = require(`../formats/${name.toLowerCase()}_${sensor}_format`).default
    
    return new Format()
  }

  

  renderChart(container, options, data = {}) {
    let chart = new ApexCharts(container, options)
    chart.render()
    return chart
  }

  buildContainer(width) {
    let container = document.createElement('div')
    container.setAttribute('width', width)
    container.setAttribute('height', (width*9/16))

    return container
  }
}

const updateChartData = (format, chart, ref, element) => {
  let startDate = element.dataset.startDate
  let endDate = element.dataset.endDate
  fetch(`/api/v1/telemetries/get_sensor_telemetries?sensor_ref=${ref}&start_date=${startDate}&end_date=${endDate}`)
  .then(response => response.json())
  .then(data => { 
    let newData = fetchData(format, data, element)
    updateChart(chart, format.getOptions(), newData)
   })
}

const fetchData = (format, data, element) => {
  let sensor = element.dataset.sensor
  let name = element.dataset.name
  let sensors = sensor.split(',')
  let names = name.split(',')
  let result = []
  const states = ( data == null) ? JSON.parse(element.dataset.states) : data
  let lastLife = states[0] == undefined ? 0 :  states[0].lifes
  if(sensor === 'live'){return [{ name: 'Vida útil', data: lastLife }]}
  
  sensors.forEach( (sensorName, indx) => {
    let processedStates =  states.map((state) => { return { x: new Date(state.created_at), y: state[sensorName] } })
    result.push({ name: names[indx], data: processedStates })
  })
  return result
}

const updateChart = (chart, newOptions = null, newSeries = null) => {
  if(newOptions === null && newSeries === null) { return }
  if(newOptions === null && newSeries !== null) {
    return chart.updateSeries(newSeries)
  }
  if(newOptions !== null && newSeries === null) {
    return chart.updateOptions(newOptions)
  }
  newOptions.series = newSeries
  chart.updateOptions(newOptions)
}