import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'tooltip' ]
  connect() {
    // Enable bootstrap popovers
    window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) { 
      return new bootstrap.Popover(popoverTriggerEl)
    })
    this.tooltipTargets.forEach((tooltip)=>{
      var popover = new bootstrap.Popover(tooltip, {
        container: 'body'
      })
      
    })
    
  }
}