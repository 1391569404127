import { Controller } from 'stimulus'
import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  connect() {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    let pinElement = document.getElementById('pins')
    let data = pinElement.dataset.pins == null ? [] :  JSON.parse(pinElement.dataset.pins)
    let center = this.getFirstLatLon(data)
    let map = this.renderMap(center)
    this.addPin(map, data)
  }

  renderMap(center){
    const map = new google.maps.Map(document.getElementById("map"),
    {
      zoom: 10,
      center: { lat: center[0], lng: center[1] },
    });
    return map
  }

  // Verifies if transfer have latiude or longitude value null
  verifyLatLon(transfer){
    if (transfer.latitude == null || transfer.longitude == null){ return false }
    return true
  }

  // Gets first latitude and longitude valid couple.
  // If there is not valid couple, set Santiago center as map center.
  getFirstLatLon(data){
    let center = [-33.4513, -70.6653]
    for(let i = 0; i < data.length; i++){
      if(this.verifyLatLon(data[i])){
        return [data[i].latitude, data[i].longitude]

      }
    }
    return center
  }

  addPin(map, data){
    for (let i = 0; i < data.length; i++) {
      let markerColor = {
        0: 'green',
        1: 'yellow',
        2: 'red'
      }
      let popupColor = {
        'green': '#F9F9F9',
        'yellow': '#ECAC07',
        'red': '#D03D42'
      }
      let fontColor = {
        'green': 'black',
        'yellow': 'black',
        'red': 'white'
      }
      // If Transfer has null longitude or latitude, skips iteration
      if(!(this.verifyLatLon(data[i]))) { continue }
      let marker = new google.maps.Marker({
        icon: new google.maps.MarkerImage(`http://maps.google.com/mapfiles/ms/icons/${markerColor[data[i].alert_level]}-dot.png`),
        position: { lat: data[i].latitude, lng: data[i].longitude },
        map
      });

      let div_content = '<div>' +
                          '<div>' +
                            '<h2 class= mt-2 text-center"> '+ data[i].transfer +' </h2>' +
                          '</div>'
      for (let j = 0; j < data[i].pin_info.length; j++){
        let row = data[i].pin_info[j]
        div_content +=    '<div>' +
                            '<div>'+ row[0] + ' | ' + row[1] + ' | ' + row[2] + 
                            '</div>' +
                          '</div>'
      }

        div_content +=    '<div class="my-3 mx-3">' +
                            '<div class="col text-center">' +
                              `<a href=/administration/transfers/${data[i].transfer_id} class="btn btn-primary text-center"> ${I18n.t('js.map.message')}</a>` + 
                            '</div>' +
                          "</div>"
                        '</div>'
      

      
      let infoBoxColor = popupColor[markerColor[data[i].alert_level]]
      let infoBoxFontColor = fontColor[markerColor[data[i].alert_level]]
      let infowindow = new InfoBox({
        content: div_content,
        boxStyle: {
          'text-align': 'center',
          'border-radius': '10px',
          'padding': "0.5 rem",
          'width': "auto",
          'height': "auto",
          'background': infoBoxColor,
          'color': infoBoxFontColor,
          'font-size': '15px'
        },
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker)
      });
    }
  }
}
