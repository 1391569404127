import { Controller } from "stimulus"
import moment from 'moment'
import I18n from 'i18n-js/index.js.erb'

let startDateField, startDate
let endDateField, endDate

export default class extends Controller {
  static targets = ["field", "saveDateRanges"]
  connect(){
    startDateField = document.getElementById('start_date')
    endDateField = document.getElementById('end_date')

    this.checkStartDate()
  }

  checkStartDate(){
    this.verifyValidRange()

    startDate = moment(startDateField.value)
    endDate = moment(endDateField.value)

    if (startDate.diff(endDate, 'days') > 30 || startDate.diff(endDate, 'days') < -30) {
      endDateField.value = startDate.add(30, 'days').format('YYYY-MM-DD')
    }
  }

  checkEndDate(){
    this.verifyValidRange()

    startDate = moment(startDateField.value)
    endDate = moment(endDateField.value)

    if (endDate.diff(startDate, 'days') > 30 || endDate.diff(startDate, 'days') < -30) {
      startDateField.value = endDate.subtract(30, 'days').format('YYYY-MM-DD')
    }
  }

  verifyValidRange(){
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale()

    startDate = moment(startDateField.value)
    endDate = moment(endDateField.value)
    if (startDate.diff(endDate, 'days') >= 1 || endDate.diff(startDate, 'days') <= -1){
      startDateField.style.borderColor = '#D40505'
      endDateField.style.borderColor = '#D40505'

      startDateField.title = I18n.t('js.report_date.warning')
      endDateField.title = I18n.t('js.report_date.warning')

      this.saveDateRangesTarget.classList.add('disabled')
    }
    else{
      startDateField.style.borderColor = ''
      endDateField.style.borderColor = ''

      this.saveDateRangesTarget.classList.remove('disabled')
    }
  }
}
