import BaseFormat from "./base_format"
import moment from 'moment'
import I18n from 'i18n-js/index.js.erb'
class RS1LiveFormat extends BaseFormat {

  defaultOptions(ref, format, element, execUpdateSeries) {
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();
    return {
      chart: {
        id: 'rs1live',
        animations: {
          enabled: false
        },
        type: 'histogram',
        width: '100%',
        height: '500',
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 25,
          tools: {
            pan: false
          },
        },
        events: {
          beforeResetZoom: function() {
            let today = new Date();
            let pastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            let startDate = pastWeek.getTime() / 1000;
            let endDate = Date.now();
            execUpdateSeries(ref, startDate, endDate, format, element, 'rs1live')
          },
          beforeZoom: function(_chartContext, {xaxis}){
            let startDate = xaxis.min / 1000;
            let endDate = xaxis.max > Date.now() ? Date.now() : xaxis.max / 1000;
            execUpdateSeries(ref, startDate, endDate, format, element, 'rs1live')
          }
        }
      },
      title: {
        text: I18n.t('js.chart.rs1live_title'),
        align: 'left',
        offsetX: 10,
        offsetY: 0,
        style: {
          fontSize:  '15px',
        },
      },
      annotations: {
        position: 'front',
        yaxis: [{
          y: 0,
          y2: 0.2,
          width: '200%',
          offsetX: -120,
          borderColor: '#EE0606',
          fillColor: '#CC1111',
          opacity: 0.2,
        }]
      },
      yaxis: {
        min: 0,
        max: 1,
        decimalsInFloat: 2,
        seriesName: I18n.t('js.chart.rp1_title'),
        labels: {
          formatter: (value) => { return `${Math.round((value + Number.EPSILON) * 100)}%` }
        }
      },
      tooltip: {
        y: {
          formatter: (val) => `${(100 * val).toFixed(2)}%`,
          title: I18n.t('js.chart.rp1_title'),
        }
      },
      title: {
        text: I18n.t('js.chart.rs1live_title'),
      },
      xaxis: { 
        type: 'category',
        labels: {
          show: false
        }
      },
      series: [
        { data: [] }
      ]
    }
  }
}

export default RS1LiveFormat