import { Controller } from "stimulus"
import I18n from 'i18n-js/index.js.erb'

let fields
let ps_min, pb_min, pc_min
let ps_max, pb_max, pc_max

export default class extends Controller {
  static targets = [  'field', 'pressureRangeHeader', 'rangeContainer', 'pressuresContainer', 'sendPressureHeader',
                      'saveRangesDesktop', 'cancelPressuresDesktop', 'sendPressuresDesktop', 'cancelRangesDesktop', 'editRangesDesktop',
                      'saveRangesMobile', 'cancelPressuresMobile', 'sendPressuresMobile', 'cancelRangesMobile', 'editRangesMobile']
  connect(){
    fields = this.fieldTargets
    ps_min = fields.find( f => f.id == 'ps_min')
    pb_min = fields.find( f => f.id == 'pb_min')
    pc_min = fields.find( f => f.id == 'pc_min')
    ps_max = fields.find( f => f.id == 'ps_max')
    pb_max = fields.find( f => f.id == 'pb_max')
    pc_max = fields.find( f => f.id == 'pc_max')

    this.checkValues()
  }
  
  editDesktop(event){
    this.pressuresContainerTarget.classList.add('d-none')
    this.rangeContainerTarget.classList.remove('d-none')
    this.saveRangesDesktopTarget.classList.remove('d-none')
    this.cancelPressuresDesktopTarget.classList.add('d-none')
    this.sendPressuresDesktopTarget.classList.add('d-none')
    this.cancelRangesDesktopTarget.classList.remove('d-none')
    this.sendPressureHeaderTarget.classList.remove('d-md-block')
    this.pressureRangeHeaderTarget.classList.add('d-md-block')
    event.target.classList.add('d-none')
  }

  cancelDesktop(event){
    this.pressuresContainerTarget.classList.remove('d-none')
    this.rangeContainerTarget.classList.add('d-none')
    this.saveRangesDesktopTarget.classList.add('d-none')
    this.cancelPressuresDesktopTarget.classList.remove('d-none')
    this.sendPressuresDesktopTarget.classList.remove('d-none')
    this.editRangesDesktopTarget.classList.remove('d-none')
    this.sendPressureHeaderTarget.classList.add('d-md-block')
    this.pressureRangeHeaderTarget.classList.remove('d-md-block')
    event.target.classList.add('d-none')
  }

  editMobile(event){
    this.pressuresContainerTarget.classList.add('d-none')
    this.rangeContainerTarget.classList.remove('d-none')
    this.saveRangesMobileTarget.classList.remove('d-none')
    this.cancelPressuresMobileTarget.classList.add('d-none')
    this.sendPressuresMobileTarget.classList.add('d-none')
    this.cancelRangesMobileTarget.classList.remove('d-none')
    this.sendPressureHeaderTarget.classList.remove('d-md-block')
    this.pressureRangeHeaderTarget.classList.add('d-md-block')
    event.target.classList.add('d-none')
  }

  cancelMobile(event){
    this.pressuresContainerTarget.classList.remove('d-none')
    this.rangeContainerTarget.classList.add('d-none')
    this.saveRangesMobileTarget.classList.add('d-none')
    this.cancelPressuresMobileTarget.classList.remove('d-none')
    this.sendPressuresMobileTarget.classList.remove('d-none')
    this.editRangesMobileTarget.classList.remove('d-none')
    this.sendPressureHeaderTarget.classList.add('d-md-block')
    this.pressureRangeHeaderTarget.classList.remove('d-md-block')
    event.target.classList.add('d-none')
  }


  checkMinMax(minField, maxField){
    let locale = document.querySelector('.current_locale')
    I18n.locale = locale ? locale.dataset.locale : 'en'
    I18n.currentLocale();

    if (parseInt(minField.value) > parseInt(maxField.value)) {
      minField.style.borderColor = '#D40505'
      minField.title = I18n.t('js.pressure_ranges.warning')
      return false
    }
    else {
      minField.style.borderColor = ''
      return true
    }
  }

  checkValues(){  
    let ps_state = this.checkMinMax(ps_min, ps_max)
    let pb_state = this.checkMinMax(pb_min, pb_max)
    let pc_state = this.checkMinMax(pc_min, pc_max)

    if (ps_state && pb_state && pc_state){
      this.saveRangesMobileTarget.classList.remove('disabled')
      this.saveRangesDesktopTarget.classList.remove('disabled')
    }
    else{ 
      this.saveRangesMobileTarget.classList.add('disabled')
      this.saveRangesDesktopTarget.classList.add('disabled') 
    }
  }
}
